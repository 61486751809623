import React, { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom';
import Loading from './Loading';
import { useUserContext } from '../contexts/userContext';
import AdminHeader from '../components/AdminHeader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SignatureCanvas from 'react-signature-canvas'
import { useTranslation } from '../contexts/TranslationContext';

const AdminTraining = () => {
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState("");
    const [ready, setReady] = useState(false);
    const [physician, setPhysician] = useState(null);
    const [staffs, setStaffs] = useState([]);
    const [staffIndex, setStaffIndex] = useState(-1);
    const { isSalonApp, handleSetTrainingDone, trainingDone, handletSetStaff } = useUserContext();
    const Swal = require('sweetalert2');
    const { translate } = useTranslation()

    //Certify details
    const signature = useRef(null);
    const [errors, setErrors] = useState('');

    const getAdminProfile = () => {
        setReady(false);
        fetch(`${process.env.REACT_APP_API}/admin/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    setPhysician(response.adminProfile);
                } else {
                    setMessage(response.message);
                }
                setReady(true);
            })
            .catch((error) => {
                console.error(error);
                setMessage(translate('error_occurred'));
                setReady(true);
            });
    }

    const getAllStaffs = () => {
        setReady(false);
        fetch(`${process.env.REACT_APP_API}/clinic/staffs`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    setStaffs(response.data);
                } else {
                    setMessage(response.message);
                }
            })
            .catch((error) => {
                console.error(error);
                setMessage(translate('error_occurred'));
            }).finally(() => {
                setReady(true);
            });
    }

    const getIsCertified = (staff) => {
        if (staff?.training_certification_name && staff?.training_certification_sign) return true;
        return false;
    }

    const handleOpenCertifyPopup = (index) => {
        if (index === staffIndex) {
            setStaffIndex(-1);
            return;
        }
        setErrors('');
        setStaffIndex(index);
    }

    const certify = () => {
        if(signature.current.isEmpty()) {
            return alert(translate('sign_before_certifying'));    
        }
        setReady(false);
        const sign = signature.current.getTrimmedCanvas().toDataURL('image/png')?.split('base64,');
        fetch(`${process.env.REACT_APP_API}/clinic/staffs/${staffs[staffIndex]?.id}/certify`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`
            },
            body: JSON.stringify({
                "name": staffs[staffIndex]?.name,
                "date": new Date(),
                "sign": sign[1],
            })
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    setStaffIndex(-1);
                    getAllStaffs();
                    setErrors('');
                    Swal.fire({
                        title: 'Success!',
                        text: translate('certified_successfully'),
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    });
                } else {
                    alert('Error! ');
                    setErrors(response.error);
                }
            })
            .catch((error) => {
                console.error(error);
                setMessage(translate('error_occurred'));
            }).finally(() => {
                setReady(true);
            });
    }

    useEffect(() => {
        getAdminProfile();
        getAllStaffs();
    }, []);

    useEffect(() => {
        const isTrainingPending = staffs?.some(member => !getIsCertified(member))
        if(!isTrainingPending && staffs?.length){
            handleSetTrainingDone(true);
            handletSetStaff(staffs);
        }
    }, [staffs])

    return (
        <>
            <main
                className={`mx-auto container flex flex-col p-5 font-font-2 tracking-wider ${ready ? "" : "hidden"
                    }`}
            >
                <AdminHeader physician={physician} />
                {message && <p className="mt-8 text-center text-red-600">{message}</p>}
                {success && (
                    <p className="mt-8 text-center text-green-600">{success}</p>
                )}
                <section className="flex px-5">
                    {/* <AdminSidebar /> */}
                    <div className="w-full p-5 flex flex-col items-center tracking-widest text-text-2" style={{ minHeight: 'calc(100vh - 138px)' }}>
                        <h1 className="mx-auto mb-6 mt-16 text-2xl text-text-2">{translate('online_training_course')}</h1>
                        <div>
                            <div className='flex items-center mt-6 justify-between'>
                                <div className='flex'>
                                    <div className='flex'>
                                        <div className='flex items-center mr-8'>
                                            <div className='w-2 h-2 bg-text-2 rounded-full' />
                                        </div>
                                        <NavLink to='/admin/training/how_to_use_grotrack_camera'>
                                            <div className='text-2xl font-normal'>{translate('how_to_use_grotrack_camera')}</div>
                                        </NavLink>
                                    </div>
                                </div>
                                <div className='flex items-center ml-14'>
                                    <div>
                                        <svg class="w-5 h-5 text-text-2 cursor-pointer" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 18">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3" />
                                        </svg>
                                    </div>
                                    <div className='m-0 ml-4 text-xs text-text-2'>
                                        <div>{translate('download')}</div>
                                        <div style={{ marginTop: '-2px' }}>{translate('user_guide')}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex items-center mt-10 justify-between mb-16'>
                                <div className='flex'>
                                    <div className='flex items-center mr-8'>
                                        <div className='w-2 h-2 bg-text-2 rounded-full' />
                                    </div>
                                    <NavLink to='/admin/training/how_to_use_grotrack_software'>
                                        <div className='text-2xl font-normal'>{translate('grotrack_software_tutorial')}</div>
                                    </NavLink>
                                </div>
                                <div className='flex items-center ml-14'>
                                    <div>
                                        <svg class="w-5 h-5 text-text-2 cursor-pointer" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 18">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3" />
                                        </svg>
                                    </div>
                                    <div className='m-0 ml-4 text-xs text-text-2'>
                                        <div>{translate('download')}</div>
                                        <div style={{ marginTop: '-2px' }}>{translate('user_guide')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-2/3 border-b-2 border-text-1' />
                        <h1 className="mx-auto mb-16 mt-14 text-2xl text-text-2">{translate('online_training_course_certification')}</h1>
                        <div>
                            {staffs?.map((staff, index) => {
                                const isCertified = getIsCertified(staff);
                                return (
                                    <div className='relative mb-12'>
                                        <div className='flex justify-between tracking-wider'>
                                            <div className='w-56 text-xl text-center'>{staff?.name}</div>
                                            <div className={`w-32 text-sm text-text-3 py-2 text-center ml-24 rounded-md cursor-pointer ${isCertified ? 'bg-background-23' : 'bg-background-24'}`} onClick={() => handleOpenCertifyPopup(index)}>{isCertified ? 'CERTIFIED' : 'CERTIFY'}</div>
                                        </div>
                                        {staffIndex === index &&
                                            (
                                                <div className='-left-14 absolute mx-auto mt-2 z-50 bg-text-3 px-16 py-6'>
                                                    <div className='text-xs text-black font-bold tracking-wide'>
                                                        {translate('certify_grotrack_training_video')}
                                                    </div>
                                                    {!staff?.training_certification_date ?
                                                        (
                                                            <SignatureCanvas  ref={signature} penColor='green' canvasProps={{className: 'sigCanvas w-full h-40 border-2 border-text-2 mt-4 bg-gray-100'}} />
                                                        ) : (
                                                            <img className='w-full h-40 border-2 border-text-2 mt-4' src={staff?.training_certification_date ? staff?.training_certification_sign : signature} alt='signature' />
                                                        )}
                                                    <span className='text-sm text-red-500'>{errors?.sign ? errors?.sign[0] : ''}</span>
                                                    <div className='flex gap-4 mt-6'>
                                                        <input disabled value={staff?.name} className='w-72 border-2 font-bold border-text-2 text-sm text-text-1 px-2 rounded-sm focus:outline-none' placeholder='Name' />
                                                        <div className='w-20'>
                                                            <DatePicker
                                                                placeholder={translate('date')}
                                                                dateFormat="dd/MM/yy"
                                                                disabled={staff?.training_certification_date}
                                                                className="w-full border-2 border-text-2 py-2 px-2 font-bold text-text-1 text-sm rounded-sm focus:outline-none"
                                                                selected={Date.parse(staff?.training_certification_date ? staff?.training_certification_date : new Date())}
                                                            />
                                                            <span className='text-sm text-red-500'>{errors?.date ? errors?.date[0] : ''}</span>
                                                        </div>
                                                        <div className={`${staff?.training_certification_date ? 'invisible' : ''} w-fit text-sm text-text-3 py-2 bg-background-20 px-4 rounded-md cursor-pointer uppercase`} onClick={certify}>{translate('confirm')}</div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors?.date ? errors?.date[0] : ''}</span>
                                                    <span className='text-sm text-red-500'>{errors?.name ? errors?.name[0] : ''}</span>
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </section>
            </main>
            <Loading message={translate('loading_details')} ready={ready} />
        </>
    )
}

export default AdminTraining;