import { React, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../assets/images/GROTrackTurquoise.png';
import Loading from '../views/Loading';
import { useUserContext } from '../contexts/userContext';
import { getLogo } from '../utlis/themeHelpers';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from "../contexts/TranslationContext";

export default function Home(props) {
  const { translate } = useTranslation();
  const history = useHistory()

  const {handleSetUser, isSalonApp, handleSetSalonTheme} = useUserContext()
  const [showLogin, setShowLogin] = useState(true);
  const [clinicName, setClinicName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [ready, setReady] = useState(true);
  const [resetPasswordEmail, setResetPasswordEmail] = useState('')


  const isSignUpValid = () => {
    return password === confirmPassword;
  };

  const login = () => {
    setReady(false);
    fetch(`${process.env.REACT_APP_API}/clinic/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email.trim().toLowerCase(),
        password: password,
      }),
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          localStorage.setItem('access_token', response.access_token);
          localStorage.setItem('expires_at', response.expires_at);
          localStorage.setItem('email', email.trim().toLowerCase());
          handleSetUser(response.clinic)
          if(response?.clinic?.type === "salon"){
            handleSetSalonTheme()
          }
          props.setLoggedIn(true);
          history.push("/staff-login");
        } else {
          setMessage(response.message);
          setReady(true);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
        setReady(true);
      });
  };

  const handleResetPassword = () => {
      setReady(false);
      fetch(`${process.env.REACT_APP_API}/clinic/password/email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: resetPasswordEmail.trim().toLowerCase(),
        }),
      })
        .then(response => response.json())
        .then(response => {
          if (response.success) {
          setReady(true);
            Swal.fire(
              translate('password_reset_link_sent'),
              translate('password_reset_link_sent_description'),
              "success"
            )
            setResetPasswordEmail('')
          } else {
            setMessage(response.message);
            setReady(true);
          }
        })
        .catch(error => {
          console.error(error);
          setMessage(translate('error_occurred'));
          setReady(true);
        });
  };

  useEffect(() => {
    if (
      !showLogin &&
      password &&
      confirmPassword &&
      password !== confirmPassword
    ) {
      setMessage(translate('passwords_do_not_match'));
    } else {
      setMessage('');
    }
  }, [showLogin, password, confirmPassword]);

  return (
    <>
      <main className={`flex flex-col p-4 font-font-2 ${ready ? '' : 'hidden'}`}>
        <NavLink to="/" className="mx-auto mt-16">
          <img className='h-20' src={getLogo(isSalonApp)} alt="GRO Track" />
        </NavLink>
        <div className="flex flex-col my-auto">
          {!showLogin && <h1 className='text-lg mb-4 text-text-2 mx-auto font-bold tracking-wider'>{translate('please_enter_your_email')}</h1>}
          {message && <p className="text-center text-red-600">{message}</p>}
          {showLogin ? (
            <>
              <form
                className="mt-4 flex flex-col items-center"
                onSubmit={event => {
                  event.preventDefault();
                  login();
                }}
              >
                <input
                  type="email"
                  className="px-2 py-1 bg-background-2 text-text-2 text-center rounded-lg focus:outline-none"
                  placeholder="email"
                  value={email}
                  required
                  onChange={event => {
                    setEmail(event.target.value);
                  }}
                />
                <input
                  type="password"
                  className="mt-8 px-2 py-1 bg-background-2 text-text-2 text-center rounded-lg focus:outline-none"
                  placeholder="password"
                  value={password}
                  required
                  minLength="8"
                  onChange={event => {
                    setPassword(event.target.value);
                  }}
                />
                <input
                  type="submit"
                  className="mt-8 h-20 w-20 text-lg rounded-full bg-background-23 text-text-3 cursor-pointer focus:outline-none"
                  value="Sign In"
                />
              </form>
              <p className="mt-4 text-center">
                {translate('forgot_your_password')} {' '}
                <button
                  className="text-button-5 hover:underline focus:outline-none focus:underline font-bold"
                  onClick={() => {
                    setShowLogin(false);
                    setMessage("")
                  }}
                >
                  {translate('click_here')}
                </button>
              </p>
            </>
          ) : (
            <>
              <form
                className="mt-4 flex flex-col items-center"
                onSubmit={event => {
                  event.preventDefault();
                  handleResetPassword();
                }}
              >
                <input
                  type="email"
                  className="mt-6 px-2 py-1 bg-background-2 text-text-2 text-center rounded-lg focus:outline-none"
                  placeholder={translate('email')}
                  value={resetPasswordEmail}
                  required
                  onChange={event => {
                    setResetPasswordEmail(event.target.value);
                    setMessage("")
                  }}
                />
                <button
                  type="submit"
                  className="mt-8 h-20 w-20 rounded-full bg-background-23 text-text-3 cursor-pointer focus:outline-none uppercase"
                >{translate('next')}</button>
              </form>
              <p className="mt-4 text-center">
                {translate('return_to_login')} {' '}
                <button
                  className="text-button-5 hover:underline focus:outline-none focus:underline font-bold"
                  onClick={() => {
                    setResetPasswordEmail('')
                    setShowLogin(true);
                    setMessage("")
                  }}
                >
                  {translate('click_here')}
                </button>
              </p>
            </>
          )}
        </div>
      </main>
      <Loading
        message={showLogin ? translate('logging_you_in') : 'Submmitting details'}
        ready={ready}
      />
      <footer className="px-4 pt-4 pb-8 mx-12 border-t-2 border-accent-2 text-center">
        {translate('gro_technologies')}
      </footer>
    </>
  );
}
