import React, { useState, useEffect } from "react";
import { NavLink, useParams, useLocation } from "react-router-dom";
import PatientNavbar from "../components/PatientNavbar";
import PresentationNavbar from "../components/PresentationNavbar";
import dayjs from "dayjs";
// import GroIndex from "../views/Overview/GroIndex";
// import GroMeasure from "../views/Overview/GroMeasure";
// import TreatmentProgress from "../assets/images/overview/treatmentProgress.jpg";
// import HomeCareProgress from "../assets/images/overview/homeCareProgress.jpg";
import Loading from "./Loading";
import OverviewProgress from "../components/OverviewProgress";
// import hairOverviewFront from "../assets/images/hairOverviewFront.png";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "../components/CheckoutForm";
import { useTranslation } from "../contexts/TranslationContext";
const stripePromise = loadStripe('pk_live_0Nh1Mvhck14s2cnlpTu26Dwm00vYHrr2id');

const HairOverview = () => {
  const location = useLocation();
  const { translate } = useTranslation();

  const [message, setMessage] = useState("");
  const [ready, setReady] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [viewMode, setViewMode] = useState("global");
  const [indexMode, setIndexMode] = useState("density");
  const [hairAnalysis, setHairAnalysis] = useState([]);
  const [currentId, setCurrentId] = useState("-1");
  const stackedData = [-100, null, null, 0, null, null, 100];
  const [image, setImage] = useState("");
  const [mlImage, setMlImage] = useState("");
  const [hairLineImage, sethairLineImage] = useState("");
  const [treatmentProgress, setTreatmentProgress] = useState(42);
  const [showRawImage, setshowRawImage] = useState(true);
  const [patientReferrals, setPatientReferrals] = useState(null);
  const [referralStatus, setReferralStatus] = useState(location.state && location.state.status);
  const [showAcceptPopup, setShowAcceptPopup] = useState(false);
  const [showPaymentGateway,  setShowPaymentGateway] = useState(false);
  const [clientSecret,  setClientSecret] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [invalidateReason, setInvalidateReason] = useState("");
  const [xCoordinate, setXCoordinate] = useState(0);
  // const stripe = useStripe();

  const chartColor = [
    "#EF8679",
    "#F7A79D",
    "#F7D2CE",
    "#FFFFFF",
    "#B4F8F2",
    "#3DE2D2",
    "#1D968B",
  ];

  const getImagePath = (array, subType, paint = null) => {
    for (var index = 0; index < array.length; index++) {
      if (paint) {
        if (array[index].subType === subType && array[index].paint === paint) {
          if(array[index].status === 'invalid'){
            return "invalid";
          }else if(array[index].status === 'invalidated'){
            if(array[index]?.reason){
              setInvalidateReason(`${translate('invalid_image_reason')} ${array[index].reason.join(', ')}`)
            }
            return "invalidated";
          }
          return array[index].image_path;
        }
      } else {
        if (array[index].subType === subType) {
          if(array[index].status === 'invalid'){
            return "invalid";
          }else if(array[index].status === 'invalidated'){
            if(array[index]?.reason){
              setInvalidateReason(`${translate('invalid_image_reason')} ${array[index].reason.join(', ')}`)
            }
            return "invalidated";
          }
          return array[index].image_path;
        }
      }
    }
    return null;
  };

  const { id, payment } = useParams();
  useEffect(() => {
    //   fetch(`${process.env.REACT_APP_API}/patient/${id}/name`, {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    //     },
    //   })
    //     .then((response) => response.json())
    //     .then((response) => {
    //       if (response.success) {
    //         setFirstName(response.firstName);
    //         setLastName(response.lastName);
    //       } else {
    //         setMessage(response.message);
    //       }
    //       setReady(true);
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //       setMessage(translate('error_occurred'));
    //     });

    fetch(`${process.env.REACT_APP_API}/hair_analysis/all?patient_id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          console.log(response);
          setFirstName(response.firstName);
          setLastName(response.lastName);
          setHairAnalysis(response.hair_analysis);
          console.log(response, "response")
          setCurrentId(0);
          setPatientReferrals(response.referral);
          if (response.referral) {
            setReferralStatus(response.referral.status);
          }
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch((error) => {
        console.error(error);
        setMessage(translate('error_occurred'));
      });

    fetch(
      `${process.env.REACT_APP_API}/patient/treatment-progress?patient_id=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        setTreatmentProgress(response?.data?.percentage);
      })
      .catch((err) => {
        setMessage(translate('error_occurred'));
      });
  }, [id]);

  useEffect(() => {
      if(payment === 'payment'){
        setReady(false);
        fetch(
          `${process.env.REACT_APP_API}/patient/${id}/client_referral_payment_success`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            body: JSON.stringify({ status: 'accepted' }),
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.payment_status) {
              setShowModal(true);
              setReferralStatus('accepted');
              setShowPaymentGateway(false);
              setClientSecret(false);
              window.history.replaceState(null, "", `/patient/presentation/overview/${id}`)
            } else {
              setMessage(response.message);
            }
            setReady(true);
          })
          .catch((error) => {
            console.error(error);
            setMessage(translate('error_occurred'));
            setReady(true);
          });
      }
            
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment]);

  // useEffect(() => {
  //   if (!stripe) {
  //     return;
  //   }

  //   // Retrieve the "payment_intent_client_secret" query parameter appended to
  //   // your return_url by Stripe.js
  //   const clientSecret = new URLSearchParams(window.location.search).get(
  //     'payment_intent_client_secret'
  //   );

  //   // Retrieve the PaymentIntent
  //   stripe
  //     .retrievePaymentIntent(clientSecret)
  //     .then(({paymentIntent}) => {
  //       // Inspect the PaymentIntent `status` to indicate the status of the payment
  //       // to your customer.
  //       //
  //       // Some payment methods will [immediately succeed or fail][0] upon
  //       // confirmation, while others will first enter a `processing` state.
  //       //
  //       // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
  //       switch (paymentIntent.status) {
  //         case 'succeeded':
  //           break;

  //         case 'processing':
  //           alert("Payment processing. We'll update you when payment is received.");
  //           break;

  //         case 'requires_payment_method':
  //           // Redirect your user back to your payment page to attempt collecting
  //           // payment again
  //           alert('Payment failed. Please try another payment method.');
  //           break;

  //         default:
  //           alert('Something went wrong.');
  //           break;
  //       }
  //     });
  // }, [stripe]);

  useEffect(() => {
    // setViewMode("global");
    if (hairAnalysis && hairAnalysis[currentId]) {
      setIndexMode("density");
      setImage(getImagePath(hairAnalysis[currentId].images.crop, "front"));
      sethairLineImage(
        getImagePath(hairAnalysis[currentId].images.hair_line, "front")
      );
    }
  }, [currentId, hairAnalysis]);

  // TODO: May be unnecessary code
  // useEffect(() => {
  //   if (hairAnalysis[0]) {
  //     setImage(
  //       getImagePath(hairAnalysis[0].images.closeup, "frontal")
  //     );
  //   }
  // }, [hairAnalysis]);

  // NOTE: Enable it to reset raw image showing
  // useEffect(() => {
  //   setshowRawImage(true);
  // }, [viewMode]);

  const toggleRawImage = () => {
    setshowRawImage(!showRawImage);
  };

  useEffect(() => {
    let imageArray = [];
    let imageArrayMl = [];
    let position = viewMode;
    if (hairAnalysis && hairAnalysis[currentId]) {
      if (viewMode === "global") {
        imageArray = hairAnalysis[currentId].images.portrait;
        imageArrayMl = hairAnalysis[currentId].images.api;
        position = "crown";
      } else {
        imageArray = hairAnalysis[currentId].images.closeup;
        imageArrayMl =  hairAnalysis[currentId].images.closeup_api;
      }
      if (viewMode === "back") {
        position = "occipital";
      }
      setImage(
        getImagePath(
          imageArray,
          position,
          null 
        )
      );
      let temp = imageArray.find((prev) => {
        return prev.subType === position;
      });
      if(temp){
        setXCoordinate(temp.scalp_position.x_start);
      }
      
      setMlImage(
        getImagePath(
          imageArrayMl,
          position,
          viewMode === "global" ? 2 : 3
        )
      );
    }
  }, [showRawImage, hairAnalysis, currentId, viewMode]);

  const setReferralValue = (value) => {
    if (value === "") {
      value = "none";
    }
    setReady(false);
    fetch(
      `${process.env.REACT_APP_API}/patient/${id}/client_referral_approve`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify({ status: value }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          if(response.action === 'payment_gateway'){
            setShowPaymentGateway(true);
            setClientSecret(response.session.client_secret);
          }else if(response.action === 'referral_accepted'){
            alert(`${response.message}`);
            setReferralStatus('accepted');
          }else{
            setMessage(response.message);
          }
          
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch((error) => {
        console.error(error);
        setMessage(translate('error_occurred'));
        setReady(true);
      });
  };

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 ${
          ready ? "" : "hidden"
        }`}
      >
        <PatientNavbar id={id} tab={6} />
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        <section className="mt-4">
          
          <div
            className="p-8 bg-background-5 overflow-hidden"
            style={{ height: "var(--height-1)" }}
          >
            <div>
            {patientReferrals ? (
              <h1 className="px-12 flex  justify-center my-3 text-lg text-center text-text-4">
                {`${firstName} ${lastName} ${translate('referred_by')} ${patientReferrals.referred_by}`}
              </h1>
            ) : (
              <h1 className="px-12 flex  justify-center my-3 text-lg text-center text-text-4">
                {`${firstName} ${lastName}`}
              </h1>
            )}
            <div className="flex items-center md:justify-evenly flex-col md:flex-row">
              <div className=" flex items-center">
                <button
                  className="font-bold text-2xl focus:outline-none"
                  onClick={function () {
                    if (currentId > 0) {
                      setCurrentId(currentId - 1);
                    }
                  }}
                >
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    stroke="#bfc5ca"
                    strokeWidth="1.25"
                  >
                    <path
                      fill="#bfc5ca"
                      d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
                    />
                  </svg>
                </button>
                <p className="mx-4 text-text-8">
                  {hairAnalysis[currentId]
                    ? dayjs
                        .unix(hairAnalysis[currentId].created_at)
                        .format("MM-DD-YY")
                    : ""}
                </p>
                <button
                  className="font-bold text-2xl focus:outline-none"
                  onClick={function () {
                    if (currentId + 1 < hairAnalysis.length) {
                      setCurrentId(currentId + 1);
                    }
                  }}
                >
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    stroke="#bfc5ca"
                    strokeWidth="1.25"
                  >
                    <path
                      fill=" #bfc5ca"
                      d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
            {console.log(location.state)}
            {referralStatus != null && !showPaymentGateway && (
              <div className="flex justify-end relative">
                <button
                  onClick={() => {
                    if (referralStatus === "pending") {
                      setShowAcceptPopup(!showAcceptPopup);
                    }
                  }}
                  className={`font-thin tracking-widest h-8 w-32 flex items-center justify-center ${
                    referralStatus === "accepted" && "bg-background-23"
                  } ${
                    referralStatus === "pending" && "bg-button-4"
                  } rounded-lg text-sm text-text-3 focus:outline-none`}
                >
                  {referralStatus === "pending" ? "Accept" : "Accepted"}
                </button>
                {showAcceptPopup && (
                  <div className="flex flex-col gap-3 bg-white w-96 items-center p-6 rounded-lg z-10 absolute top-10">
                    <p className="text-center font-light">
                      {/* I confirm that "{firstName} {lastName}" has completed a
                      consultaion and we have received {firstName} {lastName}'s
                      hair images via GroTrack Platform. */}
                      {`${translate('part_1')} ${firstName} ${lastName} ${translate('part_2')} ${translate('first_last_name_possessive')} ${firstName} ${lastName} ${translate('part_3')}`}
                    </p>
                    <button
                      onClick={() => {
                        setReferralValue("accepted");
                        setShowAcceptPopup(false);
                      }}
                      className="font-bold tracking-widest h-8 w-32 flex items-center justify-center bg-background-23 rounded-lg text-sm text-text-3 focus:outline-none"
                    >
                      {translate('confirm')}
                    </button>
                  </div>
                )}
              </div>
            )}
            {showPaymentGateway && (
              <div className="flex justify-end relative">
                <button
                  onClick={() => {
                      setShowPaymentGateway(!showPaymentGateway);
                  }}
                  className={`font-thin tracking-widest h-8 w-32 flex items-center justify-center bg-button-4 rounded-lg text-sm text-text-3 focus:outline-none`}
                >
                  {translate('cancel')}
                </button>
              </div>
            )}
            {showPaymentGateway && clientSecret && (<div className="flex justify-center ">
              <div className="w-80">
                <Elements stripe={stripePromise} options={{
                  // passing the client secret obtained in step 2
                  clientSecret: clientSecret,
                  // Fully customizable with appearance API.
                  appearance: {
                    theme: 'stripe',

                    variables: {
                      colorPrimary: '#bfc5ca',
                      colorBackground: '#ffffff',
                      colorText: '#aab7c4',
                      colorDanger: '#9e2146',
                    }
                  },
                }}>
                   <CheckoutForm id={id}/>
                </Elements>
              </div>
            </div>)}
            {!showPaymentGateway && (
            <>
            {indexMode === "density" && (
              <div className="mt-4 grid justify-items-stretch flex items-stretch mx-auto text-text-8 font-font-2">
                <div className="md:absolute lg:absolute self-center justify-self-center w-50">
                  <button
                    className={`${
                      viewMode === "global" && "text-text-7"
                    } focus:outline-none mx-2`}
                    onClick={() => {
                      setViewMode("global");
                      // setImage(
                      //   getImagePath(
                      //     hairAnalysis[currentId].images.crop,
                      //     "front"
                      //   )
                      // );
                    }}
                  >
                    {translate('global')}
                  </button>
                  <button
                    className={`${
                      viewMode === "front" && "text-text-7"
                    } focus:outline-none mx-2`}
                    onClick={() => {
                      setViewMode("front");
                      // setImage(
                      //   getImagePath(
                      //     hairAnalysis[currentId].images.closeup,
                      //     "frontal"
                      //   )
                      // );
                    }}
                  >
                    {translate('front')}
                  </button>
                  <button
                    className={`${
                      viewMode === "crown" && "text-text-7"
                    } focus:outline-none mx-2`}
                    onClick={() => {
                      setViewMode("crown");
                      // setImage(
                      //   getImagePath(
                      //     hairAnalysis[currentId].images.closeup,
                      //     "crown"
                      //   )
                      // );
                    }}
                  >
                    {translate('crown')}
                  </button>
                  <button
                    className={`${
                      viewMode === "vertex" && "text-text-7"
                    } focus:outline-none mx-2`}
                    onClick={() => {
                      setViewMode("vertex");
                      // setImage(
                      //   getImagePath(
                      //     hairAnalysis[currentId].images.closeup,
                      //     "vertex"
                      //   )
                      // );
                    }}
                  >
                    {translate('vertex')}
                  </button>
                  <button
                    className={`${
                      viewMode === "back" && "text-text-7"
                    } focus:outline-none mx-2`}
                    onClick={() => {
                      setViewMode("back");
                      // setImage(
                      //   getImagePath(
                      //     hairAnalysis[currentId].images.closeup,
                      //     "back"
                      //   )
                      // );
                    }}
                  >
                    {translate('back')}
                  </button>
                  <button
                    className={`${
                      viewMode === "left" && "text-text-7"
                    } focus:outline-none mx-2`}
                    onClick={() => {
                      setViewMode("left");
                      // setImage(
                      //   getImagePath(
                      //     hairAnalysis[currentId].images.closeup,
                      //     "left"
                      //   )
                      // );
                    }}
                  >
                    {translate('left')}
                  </button>
                  <button
                    className={`${
                      viewMode === "right" && "text-text-7"
                    } focus:outline-none mx-2`}
                    onClick={() => {
                      setViewMode("right");
                      // setImage(
                      //   getImagePath(
                      //     hairAnalysis[currentId].images.closeup,
                      //     "right"
                      //   )
                      // );
                    }}
                  >
                    {translate('right')}
                  </button>
                </div>
                <div className="self-center justify-self-end text-center w-1/4">
                  <NavLink to={`/patient/gro_report/${id}`}>{translate('gro_report')}</NavLink>
                </div>
              </div>
            )}

            <div className="mt-10 grid grid-cols-3 gap-4">
              <div className="flex items-center justify-center">
                <div className="demo-test">
                  <h2 className="text-xl font-bold text-center text-text-2">
                    {translate('gro_index')}
                  </h2>
                  <div
                    className="mt-4 flex flex-col items-center"
                    style={{ marginLeft: 60 }}
                  >
                    {/* <div className="flex flex-row items-center text-text-8">
                      <button
                        className={`focus:outline-none ${
                          indexMode === "line" && "text-text-7"
                        }`}
                        onClick={() => setIndexMode("line")}
                      >
                        Hair Line index
                      </button>
                      <button
                        className={`ml-8 focus:outline-none ${
                          indexMode === "density" && "text-text-7"
                        }`}
                        onClick={() => setIndexMode("density")}
                      >
                        Hair Density Index
                      </button>
                    </div> */}
                    {indexMode === "line" && (
                      <div className="flex flex-col mt-8">
                        {stackedData.map((data, index) => {
                          const d =
                            hairAnalysis[currentId] &&
                            hairAnalysis[currentId]?.groindex?.hairLine;
                          let i = 0;
                          if (d > 0 && d < 50) i = 4;
                          else if (d > 50 && d < 99) i = 5;
                          else if (d === 100) i = 6;
                          else if (d === 0) i = 3;
                          else if (d < 0 && d > -50) i = 2;
                          else if (d < -50 && d > -99) i = 1;
                          else i = 3;
                          return (
                            <div
                              className="flex flex-row relative hover:text-accent-3"
                              key={index}
                            >
                              <div
                                style={{
                                  backgroundColor: chartColor[index],
                                }}
                                className={`flex items-center justify-center w-16 h-8 text-text-6 font-normal transform border-2 border-transparent ${
                                  i === index &&
                                  "font-bold scale-125 z-10 rounded-lg shadow-strong border-white text-white"
                                } bg-background-3`}
                              >
                                {(index === i && parseFloat(d).toFixed(2)) ||
                                  data}
                              </div>
                              <div
                                className="flex items-center justify-center w-20 h-8 px-3 text-xs leading-none text-center "
                                style={
                                  (i === index && { color: "#ffffff" }) || {}
                                }
                              >
                                {index === 6 && "True Hair Line "}
                                {index === 3 &&
                                  `${
                                    hairAnalysis[0]
                                      ? dayjs
                                          .unix(hairAnalysis[0]?.created_at)
                                          .format("MM-DD-YY")
                                      : ""
                                  } ${translate('baseline')}`}
                                {index === i && hairAnalysis[currentId]
                                  ? dayjs
                                      .unix(hairAnalysis[currentId]?.created_at)
                                      .format("MM-DD-YY")
                                  : ""}
                              </div>
                              {index === 3 && <span class="dot-white"></span>}
                              {index === 6 && <span class="dot-blue"></span>}
                              {index === i && <span class="dot-beach"></span>}
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {indexMode === "density" && (
                      <div className="flex flex-col mt-8">
                        {stackedData.reverse().map((data, index) => {
                          const d =
                            hairAnalysis[currentId] &&
                            hairAnalysis[currentId]?.groindex?.hairDensity[
                              viewMode
                            ];
                          let i = 6;
                          if (d > 0 && d <= 50) i = 2;
                          else if (d > 50 && d <= 99) i = 1;
                          else if (d > 99) i = 0;
                          else if (d === 0) i = 3;
                          else if (d < 0 && d >= -50) i = 4;
                          else if (d < -50 && d >= -99) i = 5;
                          else if (d < -99) i = 6;
                          else i = 3;

                          return (
                            <div
                              className="flex flex-row relative hover:text-accent-3"
                              key={index}
                            >
                              <div
                                style={{
                                  backgroundColor:
                                    chartColor[chartColor.length - 1 - index],
                                }}
                                className={`flex items-center justify-center w-16 h-8 text-text-6 font-normal transform border-2 border-transparent ${
                                  i === index &&
                                  "font-bold scale-125 z-10 rounded-lg shadow-strong border-white text-white"
                                } bg-background-3`}
                              >
                                {(index === i && parseFloat(d).toFixed(2)) ||
                                  data}
                              </div>
                              <div
                                className="flex items-center justify-center w-20 h-8 px-3 text-xs leading-none text-center "
                                style={
                                  (i === index && { color: "#0eb5f0" }) || {}
                                }
                              >
                                {index === 6 && " 100% decrease"}
                                {index === 3 &&
                                  ` ${translate('baseline')} ${
                                    hairAnalysis[0]
                                      ? dayjs
                                          .unix(hairAnalysis[0]?.created_at)
                                          .format("MM-DD-YY")
                                      : ""
                                  }`}
                                {index === i &&
                                index !== 3 &&
                                hairAnalysis[currentId]
                                  ? dayjs
                                      .unix(hairAnalysis[currentId]?.created_at)
                                      .format("MM-DD-YY")
                                  : ""}
                                {index === 0 && `100% ${translate('increase')}`}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center">
                <div className="mx-auto">
                  {image === 'invalid' && (
                    <p>{translate('the_image_is_being_validated')}</p>
                  )}
                  {image === 'invalidated' && (
                    <p>{invalidateReason}</p>
                  )}
                  {indexMode === "density" && image && image !== 'invalid' && image !== 'invalidated' && (
                    <div className="relative flex justify-center">
                      {
                        ((viewMode === 'global' && showRawImage) || viewMode !== 'global')  && (
                          <img
                            src={image}
                            alt=""
                            className="h-96 cursor-pointer object-cover"
                            onClick={toggleRawImage}
                          />
                        )
                      }
                      {
                        !showRawImage && 
                        <img
                          src={mlImage}
                          alt=""
                          className={`h-96 cursor-pointer ${(viewMode !== 'global') ? 'absolute object-cover': ''} top-0`}
                          onClick={toggleRawImage}
                          style={{ left: `${xCoordinate/10 }px` }}
                        />
                      }
                    </div>
                  )}
                  {indexMode === "line" && hairLineImage && (
                    <img src={hairLineImage} alt="" className="h-96" />
                  )}

                  {!image && indexMode === "density" && (
                    <p>{translate('no_image_uploaded')}</p>
                  )}
                  {!hairLineImage && indexMode === "line" && (
                    <p>{translate('no_image_uploaded')}</p>
                  )}
                  {/* {image} */}
                </div>
              </div>
              <div className="flex items-center justify-center">
                <div className="w-full h-full flex flex-col items-center justify-center">
                  <OverviewProgress
                    title={translate('treatment_progress')}
                    subTitle={`${treatmentProgress}% ${translate('completed')}`}
                    progress={treatmentProgress / 100}
                  />
                  <div className="my-3" />
                  <OverviewProgress
                    title={translate('home_care_progress')}
                    subTitle={`0 ${translate('months')}`}
                    progress={0}
                  />
                </div>
              </div>
            </div>
            </>)}
          </div>
        </section>
        <PresentationNavbar id={id} tab={1} />
      </main>
      <Loading message={translate('loading_hair_gro_tracker')} ready={ready} />
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-slate-500 text-lg leading-relaxed">
                    {translate('success_payment_received')}
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-center px-6 py-3 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    {translate('close')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default HairOverview;
