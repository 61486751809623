import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../views/Loading';
import PatientNavbar from '../components/PatientNavbar';
import PatientSidebar from '../components/PatientSidebar';
import { useUserContext } from '../contexts/userContext';
import { useTranslation } from "../contexts/TranslationContext";

export default function PatientHealthInfo() {
  const { id } = useParams();
  const [healthDropdown, setHealthDropdown] = useState(true);
  const [examDrop, setExamDrop] = useState(false);
  const [healthHistoryDrop, setHealthHistoryDrop] = useState(false);
  const [healthInfo, setHealthInfo] = useState(null);
  const [editHealthInfo, setEditHealthInfo] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [message, setMessage] = useState('');
  const [ready, setReady] = useState(false);
  const [patientReferrals, setPatientReferrals] = useState(null);
  const {isSalonApp} = useUserContext()
  const { translate } = useTranslation();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/name`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setPatientReferrals(response.referral);
        } else {
          setMessage(response.message);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
      })
      .finally(() => {
        setReady(true);
      });
  }, [id]);

  const isHealthInfoValid = () => {
    return true;
  };
  const isHealthOpen = () => {
    return true;
  };

  const saveHealthInfo = () => {
    if (isHealthInfoValid()) {
      setReady(false);
      fetch(`${process.env.REACT_APP_API}/patient/${id}/health-info`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        body: JSON.stringify(editHealthInfo),
      })
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            setHealthInfo(response.medicalInfo);
            setEditMode(false);
          } else {
            setMessage(response.message);
          }
          setReady(true);
        })
        .catch(error => {
          console.error(error);
          setMessage(translate('error_occurred'));
          setReady(true);
        });
    }
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/health-info`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setHealthInfo(response.medicalInfo);
          setEditHealthInfo({
            ...response.medicalInfo,
            medicalConditions: [...response.medicalInfo.medicalConditions],
            historyOfAlopecia: [...response.medicalInfo.historyOfAlopecia],
            // medications: [...response.medicalInfo.medications],
            recentlyLostWeight: { ...response.medicalInfo.recentlyLostWeight },
            recentlyChildBirth: { ...response.medicalInfo.recentlyChildBirth },
            recentlyDiet: { ...response.medicalInfo.recentlyDiet },
          });
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
        setReady(true);
      });
  }, [id]);

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 font-font-2 ${ready ? '' : 'hidden'
          }`}
      >
        <div className="mx-10 border-b-2 border-gray-700 pb-5">
          <PatientNavbar id={id} tab={1} />
        </div>
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        <section className="flex px-5">
          <PatientSidebar
            healthDrop={healthDropdown}
            setHealthDrop={setHealthDropdown}
            examDrop={examDrop}
            setExamDrop={setExamDrop}
            historyDrop={healthHistoryDrop}
            setHistoryDrop={setHealthHistoryDrop}
            isHealthOpen={isHealthOpen}
            id={id}
          />
          {healthInfo && (
            <div className="mx-auto max-w-5xl">
              {patientReferrals ? (
                <h1 className="px-12 flex justify-center mt-10 text-lg text-center text-text-3">
                  {`${healthInfo.firstName} ${healthInfo.lastName} ${translate('referred_by')} ${patientReferrals.referred_by}`}
                </h1>
              ) : (
                <h1 className="px-12 flex  justify-center mt-10 text-lg text-center text-text-3">
                  {`${healthInfo.firstName} ${healthInfo.lastName}`}
                </h1>
              )}
              <form
                className="lg:mx-16 flex flex-col text-text-2"
                onSubmit={event => {
                  event.preventDefault();
                  saveHealthInfo();
                }}
              >
                <div className="mt-4 flex justify-between items-center">
                  <button
                    type="button"
                    className="px-8 py-1 bg-background-23 rounded-lg text-text-3
              cursor-pointer focus:outline-none tracking-wider font-bold"
                    onClick={() => {
                      setEditMode(!editMode);
                    }}
                  >
                    {editMode ? 'Cancel' : 'Edit'}
                  </button>
                  <p className="mx-4 tracking-widest">{translate('hair_health_information')}</p>
                  <button
                    className="px-8 py-1 bg-background-23 rounded-lg text-text-3
              cursor-pointer focus:outline-none tracking-wider font-bold"
                    disabled={!editMode}
                  >
                    {translate('save')}
                  </button>
                </div>
                <section className="mt-10">
                  <div className="grid grid-cols-4 items-center gap-4">
                    <p>{translate('health_condition')}:</p>
                    <label className="flex items-center">
                      <input
                        type="radio"
style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                        name="healthCondition"
                        value="Good"
                        checked={
                          editMode
                            ? editHealthInfo.healthCondition === 'Good'
                            : healthInfo.healthCondition === 'Good'
                        }
                        onChange={() => {
                          if (editMode) {
                            setEditHealthInfo({
                              ...editHealthInfo,
                              healthCondition: 'Good',
                            });
                          }
                        }}
                      />
                      <p className="ml-4 font-medium">{translate('good')}</p>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                        name="healthCondition
                    healthCondition"
                        value="Average"
                        checked={
                          editMode
                            ? editHealthInfo.healthCondition === 'Average'
                            : healthInfo.healthCondition === 'Average'
                        }
                        onChange={() => {
                          if (editMode) {
                            setEditHealthInfo({
                              ...editHealthInfo,
                              healthCondition: 'Average',
                            });
                          }
                        }}
                      />
                      <p className="ml-4 font-medium">{translate('average')}</p>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                        name="healthCondition"
                        value="Medical Conditions"
                        checked={
                          editMode
                            ? editHealthInfo.healthCondition ===
                            'Medical Conditions'
                            : healthInfo.healthCondition ===
                            'Medical Conditions'
                        }
                        onChange={() => {
                          if (editMode) {
                            setEditHealthInfo({
                              ...editHealthInfo,
                              healthCondition: 'Medical Conditions',
                            });
                          }
                        }}
                      />
                      <p className="ml-4 font-medium">{translate('medical_conditions')}</p>
                    </label>
                  </div>
                  <div className="mt-4 border-t-2 border-background-2 pt-4">
                    <p>{translate('list_medical_conditions')}</p>
                    <div className="mt-2 grid grid-cols-3 gap-4">
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-22 text-text-2 font-bold text-text-2 rounded-sm focus:outline-none"
                        value={
                          editMode
                            ? editHealthInfo.medicalConditions[0]
                            : healthInfo.medicalConditions[0]
                        }
                        disabled={!editMode}
                        onChange={event => {
                          setEditHealthInfo({
                            ...editHealthInfo,
                            medicalConditions: [
                              event.target.value,
                              editHealthInfo.medicalConditions[1],
                              editHealthInfo.medicalConditions[2],
                            ],
                          });
                        }}
                      />
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-22 text-text-2 font-bold text-text-2 rounded-sm focus:outline-none"
                        value={
                          editMode
                            ? editHealthInfo.medicalConditions[1]
                            : healthInfo.medicalConditions[1]
                        }
                        disabled={!editMode}
                        onChange={event => {
                          setEditHealthInfo({
                            ...editHealthInfo,
                            medicalConditions: [
                              editHealthInfo.medicalConditions[0],
                              event.target.value,
                              editHealthInfo.medicalConditions[2],
                            ],
                          });
                        }}
                      />
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-22 text-text-2 font-bold text-text-2 rounded-sm focus:outline-none"
                        value={
                          editMode
                            ? editHealthInfo.medicalConditions[2]
                            : healthInfo.medicalConditions[2]
                        }
                        disabled={!editMode}
                        onChange={event => {
                          setEditHealthInfo({
                            ...editHealthInfo,
                            medicalConditions: [
                              editHealthInfo.medicalConditions[0],
                              editHealthInfo.medicalConditions[1],
                              event.target.value,
                            ],
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="mt-8 border-t-2 border-background-2 pt-4">
                    <p>{translate('family_history_of_alopecia')}</p>
                    <div className="mt-2 grid grid-cols-3 gap-4">
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-22 text-text-2 font-bold text-text-2 rounded-sm focus:outline-none"
                        value={
                          editMode
                            ? editHealthInfo.historyOfAlopecia[0]
                            : healthInfo.historyOfAlopecia[0]
                        }
                        disabled={!editMode}
                        onChange={event => {
                          setEditHealthInfo({
                            ...editHealthInfo,
                            historyOfAlopecia: [
                              event.target.value,
                              editHealthInfo.historyOfAlopecia[1],
                              editHealthInfo.historyOfAlopecia[2],
                            ],
                          });
                        }}
                      />
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-22 text-text-2 font-bold text-text-2 rounded-sm focus:outline-none"
                        value={
                          editMode
                            ? editHealthInfo.historyOfAlopecia[1]
                            : healthInfo.historyOfAlopecia[1]
                        }
                        disabled={!editMode}
                        onChange={event => {
                          setEditHealthInfo({
                            ...editHealthInfo,
                            historyOfAlopecia: [
                              editHealthInfo.historyOfAlopecia[0],
                              event.target.value,
                              editHealthInfo.historyOfAlopecia[2],
                            ],
                          });
                        }}
                      />
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-22 text-text-2 font-bold text-text-2 rounded-sm focus:outline-none"
                        value={
                          editMode
                            ? editHealthInfo.historyOfAlopecia[2]
                            : healthInfo.historyOfAlopecia[2]
                        }
                        disabled={!editMode}
                        onChange={event => {
                          setEditHealthInfo({
                            ...editHealthInfo,
                            historyOfAlopecia: [
                              editHealthInfo.historyOfAlopecia[0],
                              editHealthInfo.historyOfAlopecia[1],
                              event.target.value,
                            ],
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="mt-8 border-t-2 border-background-2 pt-4 grid sm:grid-cols-2 gap-4">
                    <div>
                      <p>
                        {translate('recent_weight_change')}
                      </p>
                      <div className="mt-2 grid grid-cols-3 gap-4">
                        <label className="flex items-center">
                          <input
                            type="radio"
style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                            name="recentlyLostWeight"
                            value="Yes"
                            checked={
                              editMode
                                ? editHealthInfo.recentlyLostWeight.answer ===
                                'Yes'
                                : healthInfo.recentlyLostWeight.answer === 'Yes'
                            }
                            onChange={() => {
                              if (editMode) {
                                setEditHealthInfo({
                                  ...editHealthInfo,
                                  recentlyLostWeight: {
                                    answer: 'Yes',
                                    description:
                                      editHealthInfo.recentlyLostWeight
                                        .description,
                                  },
                                });
                              }
                            }}
                          />
                          <p className="ml-4 font-medium">Yes</p>
                        </label>
                        <label className="flex items-center">
                          <input
                            type="radio"
style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                            name="recentlyLostWeight"
                            value="No"
                            checked={
                              editMode
                                ? editHealthInfo.recentlyLostWeight.answer ===
                                'No'
                                : healthInfo.recentlyLostWeight.answer === 'No'
                            }
                            onChange={() => {
                              if (editMode) {
                                setEditHealthInfo({
                                  ...editHealthInfo,
                                  recentlyLostWeight: {
                                    answer: 'No',
                                    description:
                                      editHealthInfo.recentlyLostWeight
                                        .description,
                                  },
                                });
                              }
                            }}
                          />
                          <p className="ml-4 font-medium">No</p>
                        </label>
                      </div>
                    </div>
                    <div>
                      <p>{translate('recent_child_birth')}?</p>
                      <div className="mt-2 grid grid-cols-3 gap-4">
                        <label className="flex items-center">
                          <input
                            type="radio"
style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                            name="recentlyChildBirth"
                            value="Yes"
                            checked={
                              editMode
                                ? editHealthInfo.recentlyChildBirth.answer ===
                                'Yes'
                                : healthInfo.recentlyChildBirth.answer === 'Yes'
                            }
                            onChange={() => {
                              if (editMode) {
                                setEditHealthInfo({
                                  ...editHealthInfo,
                                  recentlyChildBirth: {
                                    answer: 'Yes',
                                    description:
                                      editHealthInfo.recentlyChildBirth
                                        .description,
                                  },
                                });
                              }
                            }}
                          />
                          <p className="ml-4 font-medium">Yes</p>
                        </label>
                        <select
                          className="w-full px-2 py-1 bg-background-22 font-bold text-text-2 rounded-sm focus:outline-none"
                        >
                          <option value="1">{translate('months_ago')}</option>
                          <option value="3">{translate('over_3_months_ago')}</option>
                          <option value="6">{translate('over_6_months_ago')}</option>
                        </select>
                        <label className="flex items-center">
                          <input
                            type="radio"
style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                            name="recentlyChildBirth"
                            value="No"
                            checked={
                              editMode
                                ? editHealthInfo.recentlyChildBirth.answer ===
                                'No'
                                : healthInfo.recentlyChildBirth.answer === 'No'
                            }
                            onChange={() => {
                              if (editMode) {
                                setEditHealthInfo({
                                  ...editHealthInfo,
                                  recentlyChildBirth: {
                                    answer: 'No',
                                    description:
                                      editHealthInfo.recentlyChildBirth
                                        .description,
                                  },
                                });
                              }
                            }}
                          />
                          <p className="ml-4 font-medium">{translate('no')}</p>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mt-8 border-t-2 border-background-2 pt-4 grid sm:grid-cols-2 gap-4">
                    <div>
                      <p>{translate('recent_diet_change')}</p>
                      <div className="mt-2 grid grid-cols-3 gap-4">
                        <label className="flex items-center">
                          <input
                            type="radio"
style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                            name="recentlyDiet"
                            value="Yes"
                            checked={
                              editMode
                                ? editHealthInfo.recentlyDiet.answer === 'Yes'
                                : healthInfo.recentlyDiet.answer === 'Yes'
                            }
                            onChange={() => {
                              if (editMode) {
                                setEditHealthInfo({
                                  ...editHealthInfo,
                                  recentlyDiet: {
                                    answer: 'Yes',
                                    description:
                                      editHealthInfo.recentlyDiet.description,
                                  },
                                });
                              }
                            }}
                          />
                          <p className="ml-4 font-medium">{translate('yes')}</p>
                        </label>
                        <select
                          className="w-full px-2 py-1 bg-background-22 font-bold text-text-2 rounded-sm focus:outline-none"
                        >
                          <option value="1">{translate('months_ago')}</option>
                          <option value="3">{translate('over_3_months_ago')}</option>
                          <option value="6">{translate('over_6_months_ago')}</option>
                        </select>
                        <label className="flex items-center">
                          <input
                            type="radio"
style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                            name="recentlyDiet"
                            value="No"
                            checked={
                              editMode
                                ? editHealthInfo.recentlyDiet.answer === 'No'
                                : healthInfo.recentlyDiet.answer === 'No'
                            }
                            onChange={() => {
                              if (editMode) {
                                setEditHealthInfo({
                                  ...editHealthInfo,
                                  recentlyDiet: {
                                    answer: 'No',
                                    description:
                                      editHealthInfo.recentlyDiet.description,
                                  },
                                });
                              }
                            }}
                          />
                          <p className="ml-4 font-medium">{translate('no')}</p>
                        </label>
                      </div>
                    </div>
                    <div>
                      <p>
                        {translate('have_you_recently_had_extreme_stressful_life_events')}
                      </p>
                      <div className="mt-2 grid grid-cols-3 gap-4">
                        <label className="flex items-center">
                          <input
                            type="radio"
style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                            name="recentlyStressful"
                            value="Yes"
                            checked={
                              editMode
                                ? editHealthInfo.recentlyStressful === 'Yes'
                                : healthInfo.recentlyStressful === 'Yes'
                            }
                            onChange={() => {
                              if (editMode) {
                                setEditHealthInfo({
                                  ...editHealthInfo,
                                  recentlyStressful: 'Yes',
                                });
                              }
                            }}
                          />
                          <p className="ml-4 font-medium">{translate('yes')}</p>
                        </label>
                        <label className="flex items-center">
                          <input
                            type="radio"
style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                            name="recentlyStressful"
                            value="No"
                            checked={
                              editMode
                                ? editHealthInfo.recentlyStressful === 'No'
                                : healthInfo.recentlyStressful === 'No'
                            }
                            onChange={() => {
                              if (editMode) {
                                setEditHealthInfo({
                                  ...editHealthInfo,
                                  recentlyStressful: 'No',
                                });
                              }
                            }}
                          />
                          <p className="ml-4 font-medium">{translate('no')}</p>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mt-8 border-t-2 border-background-2 pt-4 grid sm:grid-cols-2 gap-4">
                    <div className="flex flex-col">
                      <p>{translate('when_did_you_notice_your_hair_loss')}</p>
                      <div className="mt-2 grid grid-cols-3 gap-4">
                        <div></div>
                        <select
                        className="w-full px-2 py-1 bg-background-22 font-bold text-text-2 rounded-sm focus:outline-none"
                      >
                        <option value="1">{translate('just_now')}</option>
                        <option value="3">{translate('over_6_months_ago')}</option>
                        <option value="6">{translate('over_1_year_ago')}</option>
                        <option value="6">{translate('over_2_years_ago')}</option>
                        <option value="6">{translate('over_5_years_ago')}</option>
                      </select>
                        <div></div>
                      </div>
                    </div>
                    <div>
                      <p>{translate('how_much_hair_shading_do_you_experience')}</p>
                      <div className="mt-2 grid grid-cols-3 gap-4">
                        <label className="flex items-center">
                          <input
                            type="radio"
style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                            name="hairShading"
                            value="Slight"
                            checked={
                              editMode
                                ? editHealthInfo.hairShading === 'Slight'
                                : healthInfo.hairShading === 'Slight'
                            }
                            onChange={() => {
                              if (editMode) {
                                setEditHealthInfo({
                                  ...editHealthInfo,
                                  hairShading: 'Slight',
                                });
                              }
                            }}
                          />
                          <p className="ml-4 font-medium">{translate('slight')}</p>
                        </label>
                        <label className="flex items-center">
                          <input
                            type="radio"
style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                            name="hairShading"
                            value="Moderate"
                            checked={
                              editMode
                                ? editHealthInfo.hairShading === 'Moderate'
                                : healthInfo.hairShading === 'Moderate'
                            }
                            onChange={() => {
                              if (editMode) {
                                setEditHealthInfo({
                                  ...editHealthInfo,
                                  hairShading: 'Moderate',
                                });
                              }
                            }}
                          />
                          <p className="ml-4 font-medium">{translate('moderate')}</p>
                        </label>
                        <label className="flex items-center">
                          <input
                            type="radio"
style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                            name="hairShading"
                            value="Severe"
                            checked={
                              editMode
                                ? editHealthInfo.hairShading === 'Severe'
                                : healthInfo.hairShading === 'Severe'
                            }
                            onChange={() => {
                              if (editMode) {
                                setEditHealthInfo({
                                  ...editHealthInfo,
                                  hairShading: 'Severe',
                                });
                              }
                            }}
                          />
                          <p className="ml-4 font-medium">{translate('severe')}</p>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mt-8 border-t-2 border-background-2 pt-4 grid sm:grid-cols-2 gap-4">
                    <p>{translate('what_is_your_main_concern_about_your_hair_loss')}</p>
                    <div className="flex">
                      <label className="flex items-center">
                        <input
                          type="radio"
style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                          name="concernHairLoss"
                          value="Thinning Hair"
                          checked={
                            editMode
                              ? editHealthInfo.concernHairLoss ===
                              'Thinning Hair'
                              : healthInfo.concernHairLoss === 'Thinning Hair'
                          }
                          onChange={() => {
                            if (editMode) {
                              setEditHealthInfo({
                                ...editHealthInfo,
                                concernHairLoss: 'Thinning Hair',
                              });
                            }
                          }}
                        />
                        <p className="ml-4 font-medium">{translate('thinning_hair')}</p>
                      </label>
                      <label className="ml-8 flex items-center">
                        <input
                          type="radio"
style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                          name="concernHairLoss"
                          value="Balding"
                          checked={
                            editMode
                              ? editHealthInfo.concernHairLoss === 'Balding'
                              : healthInfo.concernHairLoss === 'Balding'
                          }
                          onChange={() => {
                            if (editMode) {
                              setEditHealthInfo({
                                ...editHealthInfo,
                                concernHairLoss: 'Balding',
                              });
                            }
                          }}
                        />
                        <p className="ml-4 font-medium">{translate('balding')}</p>
                      </label>
                      <label className="ml-8 flex items-center">
                        <input
                          type="radio"
style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                          name="concernHairLoss"
                          value="Receding hairline"
                          checked={
                            editMode
                              ? editHealthInfo.concernHairLoss ===
                              'Receding hairline'
                              : healthInfo.concernHairLoss ===
                              'Receding hairline'
                          }
                          onChange={() => {
                            if (editMode) {
                              setEditHealthInfo({
                                ...editHealthInfo,
                                concernHairLoss: 'Receding hairline',
                              });
                            }
                          }}
                        />
                        <p className="ml-4 font-medium">{translate('receding_hairline')}</p>
                      </label>
                    </div>
                  </div>
                  <div className="mt-8 border-t-2 border-background-2 pt-4 grid gap-4">
                    <p>{translate('have_you_had_any_treatments_for_your_hair_loss')}</p>
                    <div className="flex">
                      <label className="flex items-center">
                        <input
                          type="radio"
style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                          name="concernHairLoss"
                          value="Thinning Hair"
                          checked={
                            editMode
                              ? editHealthInfo.concernHairLoss ===
                              'Thinning Hair'
                              : healthInfo.concernHairLoss === 'Thinning Hair'
                          }
                          onChange={() => {
                            if (editMode) {
                              setEditHealthInfo({
                                ...editHealthInfo,
                                concernHairLoss: 'Thinning Hair',
                              });
                            }
                          }}
                        />
                        <p className="ml-4 font-medium">{translate('yes')}</p>
                      </label>
                      <label className="ml-8 flex items-center">
                        <input
                          type="radio"
style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                          name="concernHairLoss"
                          value="Balding"
                          checked={
                            editMode
                              ? editHealthInfo.concernHairLoss === 'Balding'
                              : healthInfo.concernHairLoss === 'Balding'
                          }
                          onChange={() => {
                            if (editMode) {
                              setEditHealthInfo({
                                ...editHealthInfo,
                                concernHairLoss: 'Balding',
                              });
                            }
                          }}
                        />
                        <p className="ml-4 font-medium">{translate('no')}</p>
                      </label>
                    </div>
                  </div>
                  <div className="pt-4 grid gap-4">
                    <p>{translate('if_yes_check_below')}:</p>
                    <div className="flex">
                      <label className="flex items-center">
                        <input
                          type="radio"
style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                          name="concernHairLoss"
                          value="Thinning Hair"
                          checked={
                            editMode
                              ? editHealthInfo.concernHairLoss ===
                              'Thinning Hair'
                              : healthInfo.concernHairLoss === 'Thinning Hair'
                          }
                          onChange={() => {
                            if (editMode) {
                              setEditHealthInfo({
                                ...editHealthInfo,
                                concernHairLoss: 'Thinning Hair',
                              });
                            }
                          }}
                        />
                        <p className="ml-4 font-medium">{translate('prp')}</p>
                      </label>
                      <label className="ml-8 flex items-center">
                        <input
                          type="radio"
style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                          name="concernHairLoss"
                          value="Balding"
                          checked={
                            editMode
                              ? editHealthInfo.concernHairLoss === 'Balding'
                              : healthInfo.concernHairLoss === 'Balding'
                          }
                          onChange={() => {
                            if (editMode) {
                              setEditHealthInfo({
                                ...editHealthInfo,
                                concernHairLoss: 'Balding',
                              });
                            }
                          }}
                        />
                        <p className="ml-4 font-medium">{translate('laser')}</p>
                      </label>
                      <label className="ml-8 flex items-center">
                        <input
                          type="radio"
style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                          name="concernHairLoss"
                          value="Receding hairline"
                          checked={
                            editMode
                              ? editHealthInfo.concernHairLoss ===
                              'Receding hairline'
                              : healthInfo.concernHairLoss ===
                              'Receding hairline'
                          }
                          onChange={() => {
                            if (editMode) {
                              setEditHealthInfo({
                                ...editHealthInfo,
                                concernHairLoss: 'Receding hairline',
                              });
                            }
                          }}
                        />
                        <p className="ml-4 font-medium">{translate('others')}</p>
                      </label>
                      <input type='text' className='px-2 py-1 flex-grow ml-8 bg-background-22 text-text-2 font-bold text-text-2 rounded-sm focus:outline-none' placeholder={translate('list_all_treatments')} />
                    </div>
                  </div>
                  <div className="mt-8 border-t-2 border-background-2 pt-4 grid gap-4">
                    <p>{translate('used_any_products_for_hair_loss')}</p>
                    <div className="flex">
                      <label className="flex items-center">
                        <input
                          type="radio"
style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                          name="concernHairLoss"
                          value="Thinning Hair"
                          checked={
                            editMode
                              ? editHealthInfo.concernHairLoss ===
                              'Thinning Hair'
                              : healthInfo.concernHairLoss === 'Thinning Hair'
                          }
                          onChange={() => {
                            if (editMode) {
                              setEditHealthInfo({
                                ...editHealthInfo,
                                concernHairLoss: 'Thinning Hair',
                              });
                            }
                          }}
                        />
                        <p className="ml-4 font-medium">{translate('yes')}</p>
                      </label>
                      <label className="ml-8 flex items-center">
                        <input
                          type="radio"
style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                          name="concernHairLoss"
                          value="Balding"
                          checked={
                            editMode
                              ? editHealthInfo.concernHairLoss === 'Balding'
                              : healthInfo.concernHairLoss === 'Balding'
                          }
                          onChange={() => {
                            if (editMode) {
                              setEditHealthInfo({
                                ...editHealthInfo,
                                concernHairLoss: 'Balding',
                              });
                            }
                          }}
                        />
                        <p className="ml-4 font-medium">{translate('no')}</p>
                      </label>
                    </div>
                  </div>
                  <div className="pt-4 grid gap-4">
                    <p>{translate('if_yes_check_below')}:</p>
                    <div className="flex">
                      <label className="flex items-center">
                        <input
                          type="radio"
style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                          name="concernHairLoss"
                          value="Thinning Hair"
                          checked={
                            editMode
                              ? editHealthInfo.concernHairLoss ===
                              'Thinning Hair'
                              : healthInfo.concernHairLoss === 'Thinning Hair'
                          }
                          onChange={() => {
                            if (editMode) {
                              setEditHealthInfo({
                                ...editHealthInfo,
                                concernHairLoss: 'Thinning Hair',
                              });
                            }
                          }}
                        />
                        <p className="ml-4 w-32 font-medium">{translate('topical_serum')}</p>
                      </label>
                      <input type='text' className='px-2 py-1 flex-grow ml-8 bg-background-22 text-text-2 font-bold text-text-2 rounded-sm focus:outline-none' placeholder={translate('list_all_treatments')} />
                    </div>
                  </div>
                  <div className="pt-4 grid gap-4">
                    <div className="flex">
                      <label className="flex items-center">
                        <input
                          type="radio"
style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                          name="concernHairLoss"
                          value="Thinning Hair"
                          checked={
                            editMode
                              ? editHealthInfo.concernHairLoss ===
                              'Thinning Hair'
                              : healthInfo.concernHairLoss === 'Thinning Hair'
                          }
                          onChange={() => {
                            if (editMode) {
                              setEditHealthInfo({
                                ...editHealthInfo,
                                concernHairLoss: 'Thinning Hair',
                              });
                            }
                          }}
                        />
                        <p className="ml-4 w-32 font-medium">{translate('supplements')}</p>
                      </label>
                      <input type='text' className='px-2 py-1 flex-grow ml-8 bg-background-22 text-text-2 font-bold text-text-2 rounded-sm focus:outline-none' placeholder={translate('list_all_treatments')} />
                    </div>
                  </div>
                  <div className="pt-4 grid gap-4">
                    <div className="flex">
                      <label className="flex items-center">
                        <input
                          type="radio"
style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                          name="concernHairLoss"
                          value="Thinning Hair"
                          checked={
                            editMode
                              ? editHealthInfo.concernHairLoss ===
                              'Thinning Hair'
                              : healthInfo.concernHairLoss === 'Thinning Hair'
                          }
                          onChange={() => {
                            if (editMode) {
                              setEditHealthInfo({
                                ...editHealthInfo,
                                concernHairLoss: 'Thinning Hair',
                              });
                            }
                          }}
                        />
                        <p className="ml-4 w-32 font-medium">{translate('others')}</p>
                      </label>
                      <input type='text' className='px-2 py-1 flex-grow ml-8 bg-background-22 text-text-2 font-bold text-text-2 rounded-sm focus:outline-none' placeholder={translate('list_all_treatments')} />
                    </div>
                  </div>
                  <div className="mt-8 border-t-2 border-background-2 pt-4 grid gap-4">
                    <p>{translate('what_is_your_expectation_for_your_hair_loss_treatment')}</p>
                    <div className="flex">
                      <input type='text' className='px-2 py-1 w-full bg-background-22 text-text-2 font-bold text-text-2 rounded-sm focus:outline-none' placeholder={translate('list_all_treatments')} />
                    </div>
                  </div>
                </section>
              </form>
            </div>
          )}
        </section>
      </main>
      <Loading message={`${translate('loading')} ${isSalonApp ? translate('client'):  translate('patient')} ${translate('health_info')}`} ready={ready} />
    </>
  );
}
