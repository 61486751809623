import React, { useRef } from 'react'
import { useState, useEffect } from 'react';
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { useLocation } from 'react-router-dom';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { BsFlagFill } from 'react-icons/bs';
import { useUserContext } from '../contexts/userContext';
import moment from 'moment';
import { useTranslation } from '../contexts/TranslationContext';

const ImageLoader = ({ img, onDoubleClick, secondaryImg, showFlagIcon = true, maxHeight = "419px", setReady, showBlackTint, bordered, showMaskAssessment, isCompareRoute, isPopup = false, handleTriggerRelaod = () => {}, mainType = "" }) => {
	// const [loading, setLoading] = useState(true);
	const { translate } = useTranslation();
	const history = useHistory()
	const [message, setMessage] = useState('');
	const [secondaryImgMessage, setSecondaryImgMessage] = useState('');
	const [showSecondaryImage, setShowSecondaryImage] = useState(true);
	const [showFlagModal, setShowFlagModal] = useState(false);
	const [description, setDescription] = useState("");
	const { pathname } = useLocation();
	const Swal = require('sweetalert2');
	const location = useLocation()
	const isDevMode = new URLSearchParams(location.search).get("mode") === 'dev';
	const { patient_id, analysis_id, graphType } = useParams();
	const { isSalonApp } = useUserContext()

	const globalMatch = useRouteMatch("/patient/:patient_id/consult/:analysis_id/global/:subType");
	const closeupMatch = useRouteMatch("/patient/:patient_id/consult/:analysis_id/closeup/:subType");
	const clientResultsMatch = useRouteMatch("/results/:patient_id/:analysis_id/:mainType/:subType");

	useEffect(() => {
		// setLoading(true);
		setMessage(getMessage(img));
		setSecondaryImgMessage(getMessage(secondaryImg));
		console.log(img, "img")
	}, [img, secondaryImg]);

	const createSupportTicket = (ticket) => {
		setShowFlagModal(false);
		setReady(false);
		fetch(
			`${process.env.REACT_APP_API}/tickets`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${localStorage.getItem("access_token")}`,
				},
				body: JSON.stringify(ticket),
			}
		).then(response => response.json()).then(response => {
			if (response.success) {
				Swal.fire({
					title: 'Success!',
					text: translate('support_ticket_created_successfully'),
					icon: 'success',
					confirmButtonText: 'Ok'
				});
			} else {
				alert('Error! ' + response.message);
			}
		}).finally(() => setReady(true));
	}

	const getMessage = (img) => {
		if (!img) {
			return translate('no_image_found');
			// setLoading(false);
		} else if (!img.image_path || img.image_path === '0') {
			const eta = img?.eta ? ` ETA: ${Math.round(img.eta / 60)}-${Math.round(img.eta / 60) + 2}  mins` : ''
			return <p className='mb-2 whitespace-nowrap'>{translate('image_processing')} {eta}</p>;
			// setLoading(false);
		} else if (img.status === 'invalidated') {
			return <ShowInvalidReason img={img} />;
			// setLoading(false);
		} else if (img.status === 'invalid') {
			return <p className="text-center text-lg mb-2 whitespace-nowrap" style={{ color: "#EF5656" }}>{translate('pending_validation')}</p>;
			// setLoading(false);
		} else if (img.status === 'approved') {
			return <p className="text-center text-turquoise-1 text-lg mb-2">{translate('validated')}</p>;
		} else {
			return "";
		}
	}

	// const handleImageLoaded = () => {
	// 	setLoading(false);
	// };

	const handleDoublieClick = () => {
		if (onDoubleClick) {
			onDoubleClick();
		}
	}

	const handleToggleSecondaryImage = () => {
		if (secondaryImg) {
			setShowSecondaryImage(prev => !prev);
		}
	}

	const reprocess = () => {
		setReady(false);
		fetch(`${process.env.REACT_APP_API}/hair_analysis/image/${img?.id}/reprocess`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("access_token")}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.success) {
					Swal.fire({
						title: 'Success!',
						text: `Successfully reprocessed`,
						icon: 'success',
						confirmButtonText: 'Ok'
					});
				}
			})
			.catch((error) => {
				console.error(error);
				setMessage(translate('error_occurred'));
			})
			.finally(() => {
				setReady(true);
			});
	}


	const deleteImage = () => {
		setShowFlagModal(false);
		Swal.fire({
			title: translate('confirm_delete_image'),
			text: translate('no_revert_warning'),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes'
		}).then((result) => {
			if (result.isConfirmed) {
				setReady(false);
				fetch(`${process.env.REACT_APP_API}/hair_analysis/image/${img?.id}/delete`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${localStorage.getItem("access_token")}`,
					},
				})
					.then((response) => response.json())
					.then((response) => {
						if (response.success) {
							Swal.fire({
								title: 'Success!',
								text: translate('image_deleted_successfully'),
								icon: 'success',
								confirmButtonText: 'Ok'
							}).then(()=>{
								if(parseInt(analysis_id) > 0 && mainType === "global") history.replace(`/patient/${patient_id}/consult/0/global/crown/hair_thinning`);
								if(parseInt(analysis_id) > 0 && mainType === "closeup") history.replace(`/patient/${patient_id}/consult/0/closeup/crown/hair_thinning`);
								if(parseInt(analysis_id) === 0) {
									history.replace(`/patient/existing`);
									return;
								}
								handleTriggerRelaod()
							});
						} else {
							Swal.fire({
								title: 'Error!',
								text: response.message,
								icon: 'error',
								confirmButtonText: 'Ok'
							});
						}
					})
					.catch((error) => {
						console.error(error);
						setMessage(translate('error_occurred'));
					})
					.finally(() => {
						setReady(true);
					});
			}
		})

	}

	return (
		<>
			<div className="relative flex flex-col items-center pt-10 w-full">
				{/* {loading && (
					<div className="absolute flex items-center justify-center w-16">
						<div className="animate-spin rounded-full h-16 w-16 border-b-2 border-gray-300"></div>
					</div>
				)} */}
				{
					message && message !== translate('no_image_found') && <p className='absolute top-0 z-50 whitespace-nowrap' style={{ transform: 'translate(0, 3px)' }}>{message}</p>
				}
				{
					!message && secondaryImgMessage !== translate('no_image_found') && secondaryImgMessage && <p className='absolute top-0 z-50 whitespace-nowrap' style={{ transform: 'translate(0, 3px)' }}>{secondaryImgMessage}</p>
				}
				{message && message === translate('no_image_found') ? (
					<div className={`${isCompareRoute ? "flex" : "hidden"} sm:flex justify-center h-56 xs:h-80 ${isCompareRoute ? "sm:h-56 md:h-64" : "sm:h-64"} lg:h-80 xl:h-96 sm:mt-2 lg:mt-0`} style={{ marginBottom: globalMatch || closeupMatch ? '74px' : '0px' }}>
						<p className="text-center text-gray-400 h-full">{message}</p>
					</div>
				) : (
					<div className='flex flex-col justify-center items-center'>
						<div className='relative' style={{ width: "fit-content" }}>
							{img?.image_path && showFlagIcon && !(clientResultsMatch || isSalonApp) && <BsFlagFill className='w-6 h-6 p-1' style={{ background: 'black', position: 'absolute', top: 5, right: 5, color: '#fff', cursor: 'pointer', zIndex: '2' }} onClick={() => { setShowFlagModal(true) }} />}
							{(showFlagModal && !(clientResultsMatch || isSalonApp)) ? (
								<>
									<div
										className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
									>
										<div className="relative w-auto my-6 mx-auto max-w-3xl" style={{ width: '480px' }}>
											{/*content*/}
											<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
												{/*body*/}
												<div className="relative p-6 flex-auto">
													<div className="text-lg text-center">{translate('request_validation')}</div>
													<div className="mt-6">
														<ImageLoader img={img} secondaryImg={secondaryImg} showFlagIcon={false} maxHeight={"250px"} isPopup={true} />
													</div>
													<div className="mt-6">
														<div className="mb-3">{translate('notes')}</div>
														<textarea value={description} onChange={(e) => setDescription(e.target.value)} className="border border-solid border-slate-200 p-3" style={{ width: '100%', height: '120px' }}></textarea>
													</div>
												</div>
												{/*footer*/}
												<div className="flex items-center justify-between px-6 py-3 border-t border-solid border-slate-200 rounded-b">
													<button
														className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
														type="button"
														onClick={() => setShowFlagModal(false)}
													>
														{translate('cancel')}
													</button>
													<button
														className="text-red-700 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
														type="button"
														onClick={deleteImage}
													>
														{translate('delete_image')}
													</button>
													<button
														className="text-green-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
														type="button"
														onClick={() => {
															createSupportTicket({ image_id: img?.id, description: description });
														}}
													>
														{translate('submit')}
													</button>
												</div>
											</div>
										</div>
									</div>
									<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
								</>
							) : null}
							<img
								src={`${img?.image_path}?width=312`}
								alt={`Paint ${img?.paint} - ${img?.mainType} ${img?.subType}`}
								className={`cursor-pointer box-border w-auto h-56 xs:h-80 ${isCompareRoute ? "sm:h-56 md:h-64" : "sm:h-64"}  lg:h-80 xl:h-96`}
								// onLoad={handleImageLoaded}
								// style={{ display: loading ? 'none' : 'block',  maxHeight: '419px'}}
								style={{ border: bordered ? '1px solid #6c6f75' : 'none' }}
								onDoubleClick={handleDoublieClick}
								onClick={handleToggleSecondaryImage}
							/>
							{img?.status !== 'invalid' && secondaryImg && secondaryImg?.image_path && secondaryImg?.image_path !== '0' && showSecondaryImage && (
								<img
									src={`${secondaryImg?.image_path}?width=312`}
									className={` cursor-pointer box-border ${showBlackTint ? `bg-black bg-opacity-50` : ''}`}
									style={{
										position: 'absolute',
										top: 0,
										left: clientResultsMatch || graphType === 'hair_thinning' || showMaskAssessment ?
											img?.ml_data?.x_min_assessment ? img?.ml_data?.x_min_assessment * 100 + '%' : 'auto' :
											img?.scalp_position?.x_start ? img?.scalp_position?.x_start * 100 + '%' : 'auto'
										,
										height: '100%',
										objectFit: 'cover',
									}}
									onClick={handleToggleSecondaryImage}
									onDoubleClick={handleDoublieClick}
								/>
							)}
						</div>
						{isDevMode && img.ml_data && !(clientResultsMatch || isSalonApp) && <div className='mt-6'>
							{Object.keys(img.ml_data).map((item, index) => {
								return (
									<h1 key={index} className="capitalize mb-3 break-words  font-bold text-text-9 text-opacity-85 text-sm leading-5 opacity-80" style={{ letterSpacing: '1.6px' }}>
										{item.split("_").join(" ")} : {img?.ml_data?.[item]}
									</h1>
								)
							})}
						</div>}
						{isDevMode && !(clientResultsMatch || isSalonApp) && (
							<div className='mt-6'>
								<button type="button" class="px-12 py-1 bg-background-23 font-bold tracking-wider rounded-lg text-text-3 cursor-pointer focus:outline-none" onClick={reprocess}>
									{translate('reprocess')}
								</button>
							</div>
						)}
						{(globalMatch || closeupMatch) && (!isPopup) && (
							<div className='w-full text-center mt-5 hidden sm:block'>
								<Link to={`/patient/${patient_id}/consult/${analysis_id}/gro_reprot`} className="w-full block bg-transparent border border-solid py-2 lg:py-3 sm:px-0 lg:px-4 font-bold text-base xxs:text-lg font-sans tracking-wider leading-5 uppercase" style={{ color: '#D0D4D7', borderColor: 'rgba(246,247,250,0.4)' }}>{translate('gro_reports')}</Link>
							</div>
						)}
					</div>
				)}
			</div>
		</>
	);
};

const ShowInvalidReason = ({ img }) => {
	const [showPopup, setShowPopup] = useState(false);
	const componentRef = useRef(null);
	const { translate } = useTranslation();
	const closePopup = () => {
		setShowPopup(prev => !prev);
	}

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (componentRef.current && !componentRef.current.contains(event.target)) {
				setShowPopup(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<p className="text-center text-lg flex items-center gap-4 relative mb-2" style={{ color: "#EF5656" }} ref={componentRef}>
			{translate('invalid')}

			<div className='relative'>
				<AiOutlineExclamationCircle className='inline text-white cursor-pointer' onClick={closePopup} />
				{
					showPopup && (
						window.innerWidth < 640 ? (
							<div className="absolute top-8 flex flex-col items-center mb-6 group-hover:flex left-1/2" style={{ transform: 'translate(-100%, 0)' }}>
								<div className="w-6 h-6 bg-white absolute right-0" style={{ transform: "rotate(45deg) translate(9px, -9px)" }}></div>
								<div className="relative z-10 p-2 w-max bg-white text-center rounded-lg" style={{ transform: 'translate(24px, 12px)' }}>
									{
										img.reason.map((reason, i) => (
											<p key={i} className='text-base font-bold leading-none text-black whitespace-nowrap'>{reason}</p>
										))
									}
								</div>
							</div>
						) : (
							<div className="absolute top-8 flex flex-col items-center mb-6 group-hover:flex left-1/2" style={{ transform: 'translate(-50%, 0)' }}>
								<div className="w-6 h-6 bg-white" style={{ transform: "rotate(45deg)" }}></div>
								<div className="relative z-10 p-2 w-max bg-white text-center rounded-lg" style={{ transform: 'translate(0, -12px)' }}>
									{
										img.reason.map((reason, i) => (
											<p key={i} className='text-base font-bold leading-none text-black whitespace-nowrap'>{reason}</p>
										))
									}
								</div>
							</div>
						)

					)
				}
			</div>


		</p>
	);
}

export default React.memo(ImageLoader);
